/* eslint-disable lingui/no-unlocalized-strings */
(function (scriptElement) {
    const isScriptLoaded = () => !!window.EventWidgetLoaded;

    const loadWidget = () => {
        window.EventWidgetLoaded = true;

        let scriptOrigin;
        try {
            const scriptURL = scriptElement.src;
            scriptOrigin = new URL(scriptURL).origin;
        } catch (e) {
            console.error('Tracksport widget error: Invalid script URL');
            return;
        }

        const widgets = document.querySelectorAll('.events-widget');
        widgets.forEach((widget, index) => {
            const eventId = widget.getAttribute('data-events-id');
            const eventSlug= widget.getAttribute('data-events-slug');
            const frameHeight= widget.getAttribute('data-events-h100');

            if (!eventId) {
                console.error('Tracksport widget error: data-events-id is required');
                return;
            }

            const style = document.createElement('style');
            style.innerHTML = `
                .event-widget-loader {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 150px; /* Увеличена височина */
                    width: 100%;
                    background: rgba(255, 255, 255, 0.9);
                    font-family: Arial, sans-serif;
                    text-align: center;
                }

                .spinner {
                    width: 40px;
                    height: 40px;
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    border-top-color: #333;
                    border-radius: 50%;
                    animation: spin 1s linear infinite;
                }

                .loading-text {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #333;
                    text-align: center;
                    max-width: 80%;
                    line-height: 1.4;
                }

                @keyframes spin {
                    to {
                        transform: rotate(360deg);
                    }
                }
            `;
            document.head.appendChild(style);

            // === 2. Показваме спинер с текст докато се зарежда iframe ===
            let loader = document.createElement('div');
            loader.className = 'event-widget-loader';

            let spinner = document.createElement('div');
            spinner.className = 'spinner';

            let loadingText = document.createElement('div');
            loadingText.className = 'loading-text';
            loadingText.innerHTML = 'Loading data. Please wait...<br> This may take a while.';

            loader.appendChild(spinner);
            loader.appendChild(loadingText);
            widget.appendChild(loader);

            const iframe = document.createElement('iframe');
            iframe.setAttribute('sandbox', '' +
                'allow-forms' +
                ' allow-scripts' +
                ' allow-same-origin' +
                ' allow-popups' +
                ' allow-popups-to-escape-sandbox' +
                ' allow-top-navigation' +
                ' allow-top-navigation-by-user-activation' +
                ' allow-downloads' +
                ' allow-modals' +
                ' allow-orientation-lock' +
                ' allow-pointer-lock' +
                ' allow-popups-to-escape-sandbox' +
                ' allow-presentation'
            );

            iframe.setAttribute('title', 'Tracksport.Events Widget');
            iframe.style.border = 'none';
            iframe.style.width = '100%';
            iframe.style.visibility = 'hidden';

            if(frameHeight) {
                iframe.style.height = '100%';
            }

            const iframeId = `events-iframe-${index}`;
            iframe.id = iframeId;

            let src = `${eventSlug}/?iframeId=${iframeId}&`;
            const params = [];
            Array.from(widget.attributes).forEach(attr => {
                if (attr.name.startsWith('data-events-') && attr.name !== 'data-events-id') {
                    const paramName = attr.name.substring(13).replace(/-([a-z])/g, (g) => g[1].toUpperCase());
                    params.push(`${paramName}=${encodeURIComponent(attr.value)}`);
                }
            });

            iframe.src = src + params.join('&');

            iframe.onload = () => {
                loader.style.display = 'none';
                iframe.style.visibility = 'visible';
            };


            widget.appendChild(iframe);

            const autoResize = widget.getAttribute('data-events-autoresize') !== 'false';

            if (autoResize) {
                window.addEventListener('message', (event) => {
                    if (event.origin !== scriptOrigin) {
                        return;
                    }

                    const {type, height, iframeId: messageIframeId} = event.data;
                    if (type === 'resize' && height && messageIframeId === iframeId) {
                        const targetIframe = document.getElementById(messageIframeId);
                        if (targetIframe) {
                            targetIframe.style.height = `${height}px`;
                        }
                    }
                });
            }
        });
    };

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', loadWidget);
    } else {
        if (!isScriptLoaded()) {
            loadWidget();
        }
    }
})(document.currentScript);
